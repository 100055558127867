<template>
  <div id="app">
    <router-view />
    <!-- 841881 -->
  </div>
</template>
<script>
export default {
  name: "a-index",
  data() {
    return {};
  },
  created() {
  },
  computed: {},
  methods: {},
};
</script>
<style  scoped>
</style>