import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vueEsign from "vue-esign";
import "@/plugin/vant.js";
import "@/registerServiceWorker.js";

// if (process.env.NODE_ENV === "production") {
//   if (window) {
//     window.console.log = function () {};
//   }
// }

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
      })
      .catch((error) => {
        console.log("Service Worker registration failed:", error);
      });
  });
}

window.onload = function () {
  const url = new URL(window.location.href);
  const source = url.searchParams.get("params");
  if (source === "custom_protocol") {
    handleCustomProtocol();
  } else {
    console.log(
      "No custom protocol source detected, proceeding with normal PWA flow."
    );
  }
  function handleCustomProtocol() {
    alert("Handling custom protocol actions...");
  }
  // let deferredPrompt;
  // window.addEventListener('beforeinstallprompt', (e) => {
  //   e.preventDefault();
  //   deferredPrompt = e;
  //   // 显示安装PWA的UI提示
  //   // ...
  // });

  // // 安装按钮的点击事件处理
  // document.querySelector('#installButton').addEventListener('click', () => {
  //   if (deferredPrompt) {
  //     deferredPrompt.prompt();
  //     // 处理用户的选择...
  //   }
  // });
};

router.afterEach(() => {
  window.scrollTo(0, 0);
});

Vue.use(vueEsign);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
