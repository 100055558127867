import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/sgoogleplay",
  },
  {
    path: "/sgoogleplay",
    component: () => import("@/views/login/sgoogleplay.vue"),
  },
  { path: "/welcome", component: () => import("@/views/welcome.vue") },
  { path: "/renzheng", component: () => import("@/views/login/renzheng.vue") },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
