import Vue from "vue";
import {
  Button,
  Tabbar,
  TabbarItem,
  Cell,
  CellGroup,
  Toast,
  Checkbox,
  CheckboxGroup,
  DatetimePicker,
  Field,
  Form,
  Radio,
  RadioGroup,
  Uploader,
  ActionSheet,
  DropdownMenu,
  DropdownItem,
  Step,
  Steps,
  IndexBar,
  IndexAnchor,
  NavBar,
  Tab,
  Tabs,
  Grid,
  GridItem,
  Icon,
  Loading,
  NoticeBar,
  Swipe,
  SwipeItem,
  Collapse,
  CollapseItem,
  Picker,
  Popup,
  Skeleton,
  Overlay,
  Calendar,
  Slider,
  Col,
  Row,
  Dialog,
  PullRefresh,
  Progress,
} from "vant";

Vue.use(Progress);

Vue.use(PullRefresh);
Vue.use(Dialog);
Vue.use(Col);
Vue.use(Row);
Vue.use(Slider);
Vue.use(Calendar);
Vue.use(Overlay);
Vue.use(Button);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(DatetimePicker);
Vue.use(Form);
Vue.use(Field);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Uploader);
Vue.use(ActionSheet);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Step);
Vue.use(Steps);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(NavBar);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Icon);
Vue.use(Loading);
Vue.use(NoticeBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Skeleton);
Vue.use(Toast);
